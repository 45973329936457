.FocusCard {
  transition: transform 0.3s;
  max-width: 300px;
  margin: 12px;
  background-color: #0e1820;
  border: 2px solid;
  border-radius: 30px;
  border-color: aqua;
}

.FocusCard:hover {
  transform: scale(1.10);
}

.image-style {
  width: 5em;
}

.card-title {
  color: aqua;
}

.card-text {
  color: aqua;

}