body {
  background-color: #0e1820;
  overflow-y: scroll;
}

#Hamburger {
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 0.35rem;
  cursor: pointer;
  position: absolute;
  z-index: 4;
  width: 50px;
  top: 60px;
  right: 60px;
}

#Initials {
  position: absolute;
  left: 40px;
  top: 40px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 40px;
  font-weight: bold;
  color: aqua;
}

.rowBurger {
  height: 0.20rem;
  width: 3.0rem;
  border-radius: 0.5rem;
  transition: transform 0.1s linear;
}

#containerBurger {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  clip-path: circle(0 at 100% 0);
  transition: clip-path 0.5s linear;
  background-color: aqua;
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: unset;
}

#IconsContainer {
  left: 24px;
  bottom: 24px;
  position: fixed;
}

.GitHubBtn {
  color: #0e1820;
  margin: 12px;
}

.EmailBtn {
  color: #0e1820;
  margin: 12px;
}

.LinkedInBtn {
  color: #0e1820;
  margin: 12px;
}

#Hamburger.hamburgerFixed {
  position: fixed;
}

.expanded {
  clip-path: circle(100%) !important;
}

.firstRow.firstRowDisplay {
  transform: rotate(45deg) translateY(0.40rem);
  background-color: #0e1820;
}

.secondRow.secondRowDisplay {
  display: none;
}

.thirdRow.thirdRowDisplay {
  transform: rotate(-45deg) translateY(-0.40rem);
  background-color: #0e1820;
}

.firstRow {
  /* transform: rotate(45deg) translateY(0.40rem); */
  transition: transform 0.1s linear;
  background-color: aqua;
}

.secondRow {
  transition: transform 0.1s linear;
  background-color: aqua;
  /* display: none; */
}

.thirdRow {
  /* transform: rotate(-45deg) translateY(-0.40rem); */
  transition: transform 0.1s linear;
  background-color: aqua;
}

.carousel-image-style {
  width: auto;
  height: 500px;
  max-height: 500px;
}

/* #MyAppBar {
  position: fixed;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 2;
  animation: fadeIn ease 4s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
} */

#MySpheres {
  position: fixed;
  top: 0;
  /* background-position: center;
  background-repeat: no-repeat;
  background-size: cover; */
  z-index: 0;

  animation: fadeIn ease 4s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

#TitleMessage {
  font-size: 40px;
  margin-bottom: 48px;
}

#MyContent {
  text-align: center;
  z-index: 1;

  animation: fadeIn ease 4s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  position: relative;
  overflow-y: hidden;
}

#Divider {
  margin: auto;
  max-width: 425px;
  margin-bottom: 48px;
  background-color: aqua;
}

@media only screen and (max-width: 600px) {
  #Divider {
    max-width: 70%;
  }
}

#Skills {
  color: aqua;
  margin-top: 48px;
}

#About {
  color: aqua;
}

#Projects {
  color: white;
  background-color: #5969e1;
}

#MyProjects {
  padding: 5em;
}

#Paragraphs {
  margin: 12px;
  margin-bottom: 36px;
  max-width: 450px;
  text-align: left;
}

#LetsTalk {
  font-size: 24px;
  position: fixed;
  color: #0e1820;
  bottom: 240px;
  left: 24px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}



/* Vertical Arrows start */
.arrows {
  width: 60px;
  height: 72px;
  position: absolute;
  left: 50%;
  margin-left: -30px;

  animation: fadeIn ease 10s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.arrows path {
  stroke: aqua;
  fill: transparent;
  stroke-width: 2px;
  animation: arrow 2s infinite;
  -webkit-animation: arrow 2s infinite;
}

@keyframes arrow {
  0% {
    opacity: 0
  }

  40% {
    opacity: 1
  }

  80% {
    opacity: 0
  }

  100% {
    opacity: 0
  }
}

@-webkit-keyframes arrow {
  0% {
    opacity: 0
  }

  40% {
    opacity: 1
  }

  80% {
    opacity: 0
  }

  100% {
    opacity: 0
  }
}

.arrows path.a1 {
  animation-delay: -1s;
  -webkit-animation-delay: -1s;
}

.arrows path.a2 {
  animation-delay: -0.5s;
  -webkit-animation-delay: -0.5s;
}

.arrows path.a3 {
  animation-delay: 0s;
  -webkit-animation-delay: 0s;
}

/* Vertical Arrows end */